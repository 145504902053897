import { ReactNode } from "react";

// Import UIs
import Header from "./partials/Header";

interface Props {
  children: ReactNode;
  hideHeader?: boolean;
}

function Layout({ children, hideHeader }: Props) {
  return (
    <div className="app-wrapper">
      {hideHeader !== true && <Header />}
      <main className="main-page">{children}</main>
    </div>
  );
}

export default Layout;
