import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  NavbarToggler,
  Collapse,
} from "reactstrap";

// Import Static Images
import Logo from "../../assets/images/logo.png";

// Static UI imports
import Menu from "./Menu";

function Header() {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  useEffect(() => {
    const header = document.getElementById("appHeader");
    if (header) {
      const sticky = header.offsetTop;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("is-sticky");
        } else {
          header.classList.remove("is-sticky");
        }
      });
    }
  });

  return (
    <header className="header-horizontal header-horizontal-sm" id="appHeader">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="w-100 d-flex align-items-center justify-content-between">
              <a href="/" className="header-logo header-logo-sm">
                <img
                  src={Logo}
                  width="250"
                  height="34"
                  alt="PlonkBot"
                  className="img-fluid"
                />
              </a>
              <div className="header-horizontal-main header-horizontal-main-sm ml-auto">
                <div className="navigation navigation-md">
                  <Menu className="navigation-list" />
                </div>

                <Navbar
                  color="faded"
                  light
                  className={`mobile-toggler ${
                    collapsed ? `navigation-active` : ``
                  }`}
                >
                  <NavbarToggler
                    className=""
                    onClick={() => setCollapsed(!collapsed)}
                  />
                  <Collapse
                    className="responsive-navigation"
                    isOpen={collapsed}
                  >
                    <div
                      className="close-menu"
                      style={{ display: "flex" }}
                      data-uw-styling-context="true"
                      onClick={() => setCollapsed(!collapsed)}
                    >
                      <span
                        className="icon-close"
                        data-uw-styling-context="true"
                      ></span>
                    </div>
                    <Menu setCollapsed={setCollapsed} collapsed={collapsed} />
                  </Collapse>
                  {collapsed && (
                    <div
                      className="menu-overlay"
                      onClick={() => setCollapsed(!collapsed)}
                    ></div>
                  )}
                </Navbar>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default Header;
