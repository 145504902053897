import UnauthenticatedLayout from "../../layouts/UnauthenticatedLayout";

// Import Sections
import SectionHero from "../home/partials/SectionHero";
import SectionFeatures from "../home/partials/SectionFeatures";
import SectionSocial from "../home/partials/SectionSocial";

const HomePage = () => {
  return (
    <UnauthenticatedLayout>
      <SectionHero />
      <SectionFeatures />
      <SectionSocial />
    </UnauthenticatedLayout>
  );
};

export default HomePage;
