import UnauthenticatedLayout from "../../layouts/UnauthenticatedLayout";

// Import Sections
import SectionPrivacy from "./partials/SectionPrivacy";
import SectionSocial from "../home/partials/SectionSocial";

const HomePage = () => {
  return (
    <UnauthenticatedLayout hideHeader={true}>
      <SectionPrivacy />
      <SectionSocial />
    </UnauthenticatedLayout>
  );
};

export default HomePage;
