import { Container, Row, Col } from "reactstrap";

// Import Sections

// Static Assets
import Icon from "../../../layouts/partials/Icon";

function SectionSocial() {
  return (
    <section className="social-section z-over" id="Social">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} md={12} sm={12} className="order-1 order-lg-2">
            <div className="social-content">
              <h4>Join the $Plonkbot Community</h4>
              <div className="cta-button d-flex justify-content-center gap-4">
                <a
                  href="https://twitter.com/plonkbot"
                  className="button button-social button-twitter"
                >
                  <Icon
                    name="Twitter"
                    fill="currentColor"
                    size="36"
                    role="button"
                  />
                </a>
                <a
                  href="https://t.me/plonkbotjoin"
                  className="button button-social button-telegram"
                >
                  <Icon
                    name="Telegram"
                    fill="currentColor"
                    size="40"
                    role="button"
                  />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default SectionSocial;
