const Icons = {
  Telegram: () => (
    <path d="M14.6 1.7s1.5-.6 1.4.8c0 .6-.4 2.5-.7 4.6l-1 6.2s-.1.9-.8 1.1c-.7.2-1.9-.6-2.1-.7-.2-.1-3.1-1.9-4.1-2.8-.3-.2-.6-.7 0-1.3l4.3-4c.5-.5 1-1.6-1.1-.2L4.9 9.2s-.7.4-1.9 0L.3 8.4s-1-.6.7-1.2c4.1-1.8 9.2-3.7 13.6-5.5z" />
  ),
  Twitter: () => (
    <path d="M12.6.2h2.5L9.7 6.8l6.3 9h-4.9l-3.9-5.5-4.4 5.5H.3L6 8.7 0 .2h5.1l3.5 5 4-5zm-.8 14h1.4L4.3 1.7H2.9l8.9 12.5z" />
  ),
} as const;

export type IconNames = keyof typeof Icons;
export default Icons;
