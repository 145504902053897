import { Container, Row, Col } from "reactstrap";

function SectionFeatures() {
  return (
    <section className="features-section z-over">
      <Container>
        <Row className="justify-content-center">
          <Col lg={10} md={12} sm={12} className="order-1 order-lg-2">
            <div className="section-head text-center">
              <h2 className="title text-gradient">Privacy Policy</h2>
            </div>
            <p>
              Thank you for choosing the PlonkBot chrome extension, we value
              your privacy and are dedicated to safeguarding it by adhering to
              this policy. This document outlines the types of information we
              might collect from you or that you might provide when you install
              and use the PlonkBot chrome extension. It also explains our
              practices regarding the collection, usage, maintenance,
              protection, and disclosure of that information.
            </p>
            <h3>Information We Collect and How We Collect It</h3>
            <p>
              The PlonkBot chrome extension does not gather any personal
              information. No registration is required, and the extension lacks
              the ability to track your personal data over time or across
              third-party websites. However, the extension employs permissions
              such as "storage" to enhance your experience by allowing
              permission for the extension to save settings and preferences
              locally on your device.
            </p>
            <p>
              Since the PlonkBot chrome extension does not collect personal
              information, it will never use or share your personal data. The
              locally stored data is solely for the purpose of improving your
              user experience and functionality on your local device.
            </p>
            <h3>Disclosure of Your Information</h3>
            <p>
              As no personal information is collected, we do not share, sell,
              rent, or trade any information with third parties for promotional
              purposes.
            </p>
            <h3>Data Security</h3>
            <p>
              We are committed to keeping your information secure. While the
              extension uses local storage to save data, it is important to
              recognize that no method of electronic storage or transmission
              over the internet is entirely secure. Therefore, we cannot
              guarantee absolute security.
            </p>
            <h3>Changes to Our Privacy Policy</h3>{" "}
            <p>
              We may update our Privacy Policy periodically. Any changes will be
              posted on this page. You are encouraged to review this Privacy
              Policy occasionally to stay informed of any updates. Changes to
              this Privacy Policy become effective when they are posted on this
              page.
            </p>
            <h3>Contact Information</h3>
            <p>
              If you have any questions regarding this privacy policy, you're
              welcome to contact us:
            </p>
            <p>
              Email: plonkbot@proton.me
              <br />
              Website: https://plonkbot.com
              <br />
              Policy Last Updated: 6/30/2024
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default SectionFeatures;
