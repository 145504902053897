import qs from "query-string";
import { FunctionComponent, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

interface Props {
  isLoggedIn: boolean | null;
  defaultRoute: string;
}

const PublicRoute: FunctionComponent<Props> = ({
  isLoggedIn,
  defaultRoute,
}: Props) => {
  const { pathname } = useLocation();
  const { redirectUrl } = qs.parse(window.location.search);

  //react keeps the browser position when clicking on Link, this will make the browser to scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (isLoggedIn) {
    return (
      <Navigate
        to={redirectUrl ? (redirectUrl as string) : defaultRoute}
        replace={true}
      />
    );
  }

  return <Outlet />;
};

export default PublicRoute;
