//imports
import { Col, Row, Container } from "reactstrap";

const NotFound = () => {
  return (
    <div className="error error-xs">
      <Container>
        <Row>
          <Col>
            <div className="error_main">Not Found</div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotFound;
