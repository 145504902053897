import React from "react";
import { Container, Row, Col } from "reactstrap";

// Static Assets
import AutoSell from "../../../assets/images/icons/AutoSell.png";
import AutoSnipe from "../../../assets/images/icons/AutoSnipe.png";
import Referrals from "../../../assets/images/icons/Referrals.png";
import Raydium from "../../../assets/images/icons/Raydium.png";
import CopyTrade from "../../../assets/images/icons/CopyTrade.png";
import PrivateEnquiries from "../../../assets/images/icons/PrivateEnquiries.png";

function SectionFeatures() {
  return (
    <section className="features-section z-over" id="Features">
      <Container>
        <div className="section-head text-center">
          <h2 className="title text-gradient">Features</h2>
          <p>Instant notifications and lambo speed trading!</p>
        </div>
        <Row>
          <Col lg={4} md={6} sm={12} className="order-2 order-lg-1">
            <div className="icon-box-wraper text-center">
              <div className="icon-media">
                <img
                  alt="Raydium"
                  src={Raydium}
                  srcSet={Raydium}
                  width="100"
                  height="100"
                  className="img-fluid"
                />
              </div>
              <h3>Dex</h3>
              <p>
                Trade tokens on all major Dex platforms as well as pump.fun and
                moonshot. Platforms like Raydium, Orca, Meteora etc are all
                supported!
              </p>
            </div>
          </Col>
          <Col lg={4} md={6} sm={12} className="order-2 order-lg-1">
            <div className="icon-box-wraper text-center">
              <div className="icon-media">
                <img
                  alt="Auto Snipe"
                  src={AutoSnipe}
                  srcSet={AutoSnipe}
                  width="100"
                  height="100"
                  className="img-fluid"
                />
              </div>
              <h3>Auto Snipe</h3>
              <p>
                Ensure you get in early for any token that matches your
                predefined filters. If you find a token that you think will take
                off if it makes it to Raydium, you’re also able to snipe the
                migration!
              </p>
            </div>
          </Col>
          <Col lg={4} md={6} sm={12} className="order-2 order-lg-1">
            <div className="icon-box-wraper text-center">
              <div className="icon-media">
                <img
                  alt="Auto Sell"
                  src={AutoSell}
                  srcSet={AutoSell}
                  width="100"
                  height="100"
                  className="img-fluid"
                />
              </div>
              <h3>Auto Sell</h3>
              <p>
                Trade in full AFK mode with features like Take Profit, Stop
                Loss, Trailing Stop Loss, Raydium Sell, Bonding Curve
                Sell....and the list goes on!
              </p>
            </div>
          </Col>

          <Col lg={4} md={6} sm={12} className="order-2 order-lg-1">
            <div className="icon-box-wraper text-center">
              <div className="icon-media">
                <img
                  alt="Copy Trade"
                  src={CopyTrade}
                  srcSet={CopyTrade}
                  width="100"
                  height="100"
                  className="img-fluid"
                />
              </div>
              <h3>Copy Trade</h3>
              <p>
                If you’ve found some smart wallets with a nice win rate, using
                copy trade you can ride on their coat tails and follow their
                lead!
              </p>
            </div>
          </Col>
          <Col lg={4} md={6} sm={12} className="order-2 order-lg-1">
            <div className="icon-box-wraper text-center">
              <div className="icon-media">
                <img
                  alt="Referrals"
                  src={Referrals}
                  srcSet={Referrals}
                  width="100"
                  height="100"
                  className="img-fluid"
                />
              </div>
              <h3>Referrals</h3>
              <p>
                Refer friends and fam and earn 30% in the first month and 25%
                for life. Ambassadors program available, reach out in Telegram
                for more info.
              </p>
            </div>
          </Col>
          <Col lg={4} md={6} sm={12} className="order-2 order-lg-1">
            <div className="icon-box-wraper text-center">
              <div className="icon-media">
                <img
                  alt="Private Enquiries"
                  src={PrivateEnquiries}
                  srcSet={PrivateEnquiries}
                  width="100"
                  height="100"
                  className="img-fluid"
                />
              </div>
              <h3>Private Enquiries</h3>
              <p>
                Have a need for bespoke trading solutions? Jump in to Telegram
                and chat with the team for any unique requirements.
              </p>
            </div>
          </Col>
        </Row>
        <div className="w-100 text-center">
          <div className="cta-button justify-content-center">
            <div className="header-button me-3 me-lg-4">
              <a
                href="https://t.me/plonkbotjoin"
                className="button button-rounded button-primary"
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default SectionFeatures;
