import { Container, Row, Col } from "reactstrap";

// Import Static Images
import MainCharacter from "../../../assets/images/pil-hammer.gif";

export default function App() {
  return (
    <section className="home-section" id="Home">
      <div className="home-section-wrap">
        <Container>
          <Row className="align-items-center">
            <Col lg={5} className="order-2 order-lg-1">
              <div className="w-100 mw-450px">
                <img
                  alt="PlonkBot"
                  src={MainCharacter}
                  srcSet={MainCharacter}
                />
              </div>
            </Col>

            <Col lg={7} className="order-1 order-lg-2">
              <div className="hero-slider-wrapper ps-lg-5">
                <h2 className="heading heading-xs text-gradient">
                  Pump.fun, Moonshot & Raydium | Trading & Notifications Bot
                </h2>
                <p className="description description-xs">
                  Make more trading on pump.fun, moonshot & raydium with instant
                  notifications & trading straight from telegram. Trade like a
                  pro with PlonkBots Copy Trade, Snipe, Take Profit, Stop Loss,
                  Migration Snipe & Sell, lightning fast transactions & much
                  more.
                </p>
                <div className="cta-button">
                  <div className="header-button me-3 me-lg-4">
                    <a
                      href="https://t.me/plonkbotjoin"
                      className="button button-rounded button-primary"
                    >
                      Join Now
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}
