import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";

interface Props {
  collapsed?: boolean;
  setCollapsed?: (val: boolean) => void;
  className?: string;
}

function Menu({ collapsed, setCollapsed, className }: Props) {
  return (
    <Nav
      navbar={className ? false : true}
      className={`${className ? className : ``}`}
    >
      <NavItem>
        <NavLink
          to="/"
          className="nav-link"
          onClick={() => (setCollapsed ? setCollapsed(!collapsed) : null)}
        >
          Home
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          to="https://docs.plonkbot.com/"
          className="nav-link"
          onClick={() => (setCollapsed ? setCollapsed(!collapsed) : null)}
          target="_blank"
        >
          Docs
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          to="https://twitter.com/plonkbot"
          className="nav-link"
          onClick={() => (setCollapsed ? setCollapsed(!collapsed) : null)}
          target="_blank"
        >
          Twitter
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          to="https://t.me/plonkbotjoin"
          className="nav-link"
          onClick={() => (setCollapsed ? setCollapsed(!collapsed) : null)}
          target="_blank"
        >
          Telegram
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          to="https://twitter.com/plonkbot"
          className="nav-link"
          onClick={() => (setCollapsed ? setCollapsed(!collapsed) : null)}
          target="_blank"
        >
          Support
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          to="https://dexscreener.com/solana/8wvppyv1c3efwcnezneuunkr167cewzr9ynvpwnhdac9"
          className="nav-link"
          onClick={() => (setCollapsed ? setCollapsed(!collapsed) : null)}
          target="_blank"
        >
          Dex Screener
        </NavLink>
      </NavItem>
    </Nav>
  );
}

export default Menu;
