import { Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import PublicRoute from "./PublicRoute";
import RouteConstants from "../config/Constant";
import HomePage from "../pages/home";
import PrivacyPage from "../pages/privacy";
import Notfound from "../pages/notFound";

function RouteComponent() {
  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route
            element={
              <PublicRoute
                isLoggedIn={false}
                defaultRoute={`${RouteConstants.LANDING_PAGE}`}
              />
            }
          >
            <Route
              path={`${RouteConstants.LANDING_PAGE}`}
              element={<HomePage />}
            />
            <Route
              path={`${RouteConstants.PRIVACY_PAGE}`}
              element={<PrivacyPage />}
            />
          </Route>

          {/* Fallback - Not Found */}
          <Route path={RouteConstants.NOT_FOUND} element={<Notfound />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default RouteComponent;
